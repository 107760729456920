import React from "react";
import ExpandedAccordionRow from "./ExpandedAccordionRow";

export default function AccordionRows(props) {
  const courseInfo = props["data-courseInfo"];

  let strippedCourseId = courseInfo.courseId.replace(/[^\w\s]/gi, "");
  let semester_name = window.currentSemesterName;

  return (
    <div className="accordion-rows">
      <div className="panel-group" id="accordion">
        <ExpandedAccordionRow
          title="Beschreibung"
          courseId={strippedCourseId}
          courseProp={courseInfo.desc}
          idx="1"
        />
        <ExpandedAccordionRow
          title="Voraussetzungen"
          courseId={strippedCourseId}
          courseProp={courseInfo.prereq}
          idx="2"
        />
        <ExpandedAccordionRow
          title="Zusätzliche Information"
          courseId={strippedCourseId}
          courseProp={courseInfo.addInfo}
          idx="3"
        />
        <ExpandedAccordionRow
          title="Exam Info"
          courseId={strippedCourseId}
          courseProp={courseInfo.examContent}
          idx="4"
        />
        <ExpandedAccordionRow
          title="Literatur"
          courseId={strippedCourseId}
          courseProp={courseInfo.courseLit}
          idx="5"
        />

        <div className="panel panel-default">
          <div className="panel-heading">
            <h4 className="panel-title">
              <span>Externe Links: </span>
              <a
                style={{ color: "#f49800" }}
                href={courseInfo.timeTableLink}
                target="_blank"
                rel="noreferrer"
              >
                Zeitplan
              </a>{" "}
              | &nbsp;
              <a
                style={{ color: "#f49800" }}
                href={
                  "http://tools.unisg.ch/Handlers/public/CourseInformationSheet.ashx?Semester=" +
                  semester_name +
                  "&EventNumber=" +
                  courseInfo.courseId
                }
                target="_blank"
                rel="noreferrer"
              >
                Merkblatt{" "}
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}
