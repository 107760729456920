import React, { useState } from "react";
import axios from "axios";
import moment from "moment-timezone";

// import { actionCreators } from './../reducers/courseListRedux'
import CourseTable from "./components/CourseTable/CourseTable";
import CourseCalendar from "./components/CourseCalendar/CourseCalendar";
import "./styles/mainTheme.scss";
import { useEffect } from "react";

const TERM_ID =
  process.env.REACT_APP_SEMESTER_ID || "c16912a2-a41e-4e18-95ad-11aa89d1162f";
const APP_ID =
  process.env.REACT_APP_APP_ID || "229cf8dd-27a5-42a7-9783-031773649878";

function App() {
  const [courseData, setCourseData] = useState(null);
  const [selectedCourseIdxList, setSelectedCourseIdxList] = useState([]);
  const [eventsFromSelectedCourses, setEventsFromSelectedCourses] = useState(
    []
  );

  const [token, setToken] = useState(localStorage.getItem("token"));

  const urlParams = new URLSearchParams(window.location.search);
  const passedToken = urlParams.get("token");

  const addCalendarEvents = React.useCallback(
    (courseIndexList) => {
      let events = [];
      courseIndexList.forEach(function (idx) {
        // select the events from the selectedCourses by index in courseData
        events = events.concat(
          courseData[idx].schedule.map(function (event) {
            // make data usable for bigCalendar
            return {
              id: event.id,
              title: event.title,
              start: (event.start = moment(event.date).toDate()),
              end: (event.end = moment(event.date)
                .add(event.duration, "minutes")
                .toDate()),
            };
          })
        );
      });
      return events;
    },
    [courseData]
  );

  useEffect(() => {
    if (passedToken) {
      setToken(passedToken);
      localStorage.setItem("token", passedToken);
      window.history.replaceState({}, document.title, "/");
    }
  }, [passedToken]);

  useEffect(() => {
    if (token) {
      const getCourseData = async () => {
        try {
          const response = await axios.get(
            "https://integration.unisg.ch/eventapi/CourseInformationSheets/myLatestPublishedPossiblebyTerm/" +
              TERM_ID,
            {
              headers: {
                "X-ApplicationId": APP_ID,
                "X-RequestedLanguage": "de",
                "API-Version": 1,
                Authorization: "Bearer " + token,
              },
            }
          );
          const allCourses = [];
          response.data.forEach((c) =>
            allCourses.push(...generateCourseObject(c))
          );

          let selectedCourses = localStorage.getItem("selectedCourses");

          if (selectedCourses) {
            try {
              selectedCourses = JSON.parse(
                localStorage.getItem("selectedCourses")
              );

              if (selectedCourses?.length > 0) {
                let events = [];
                selectedCourses.forEach((scIdx) => {
                  if (allCourses[scIdx]) {
                    allCourses[scIdx].checked = true;

                    const courseEvents = allCourses[scIdx].schedule.map(
                      function (event) {
                        // make data usable for bigCalendar
                        return {
                          id: event.id,
                          title: event.title,
                          start: (event.start = moment(event.date).toDate()),
                          end: (event.end = moment(event.date)
                            .add(event.duration, "minutes")
                            .toDate()),
                        };
                      }
                    );
                    events = events.concat(courseEvents);
                  }
                });
                setSelectedCourseIdxList(selectedCourses);
                setEventsFromSelectedCourses(events);
              }
            } catch (err) {
              console.error(err);
              localStorage.clear();
            }
          }

          setCourseData(allCourses);
        } catch (err) {
          console.error(err);
          localStorage.clear();
          window.location = "https://shsg.ch/services/kurskatalog";
        }
      };

      getCourseData();
    }
  }, [token]);

  const updateCalendarEvents = React.useCallback(
    (coursesToModify, modType) => {
      let newEvents = [];
      if (modType === "del") {
        // Loop through coursesToModify and get their schedules
        coursesToModify.forEach(function (courseIdx) {
          const schedToRemove = courseData[courseIdx].schedule;
          // Put event_id of each event in schedule to array
          const eventsToRemove = schedToRemove.map(function (event) {
            return event.id;
          });

          // remove elements with same ID from eventsFromSelectedCourses
          newEvents = newEvents.concat(
            eventsFromSelectedCourses.filter(function (event) {
              return eventsToRemove.includes(event.id) !== true;
            })
          );
        });
      } else if (modType === "add") {
        newEvents = eventsFromSelectedCourses.concat(
          addCalendarEvents(coursesToModify)
        );
      } else {
        //catchall
        return;
      }
      // set to state
      setEventsFromSelectedCourses(newEvents);
    },
    [addCalendarEvents, eventsFromSelectedCourses, courseData]
  );

  const handleTableCheckbox = (rowInfo) => {
    // if checkbox is clicked
    let courseRow = rowInfo.original;

    const newSelectedCourseIdxList = [...selectedCourseIdxList];

    // var toDelete = new Set([courseRow.courseId])
    const curIdx = courseData.findIndex(
      (x) => x.courseId === courseRow.courseId
    );
    const courseIdx = newSelectedCourseIdxList.indexOf(curIdx);
    if (courseIdx > -1) {
      //is currently selected so we have to uncheck
      updateCalendarEvents([curIdx], "del");
      newSelectedCourseIdxList.splice(courseIdx, 1); //remove index
    } else {
      // add index to list
      newSelectedCourseIdxList.push(curIdx); //pushvalue
      updateCalendarEvents([curIdx], "add");
    }

    setSelectedCourseIdxList(newSelectedCourseIdxList);
    localStorage.setItem(
      "selectedCourses",
      JSON.stringify(newSelectedCourseIdxList)
    );
  };

  if (!token && !passedToken) {
    window.location = "https://shsg.ch/services/kurskatalog";
    return (
      // put loading wheel here
      <div className="text-center">
        <h1>You must be logged in to access this service</h1>
      </div>
    );
  } else if (!courseData || courseData.length < 1) {
    return (
      // put loading wheel here
      <div className="text-center">
        <h1> Please wait while we load your course Data </h1>
      </div>
    );
  } else {
    return (
      <div>
        <div className="container">
          <div className="row">
            <CourseTable
              courseData={courseData}
              eventsList={eventsFromSelectedCourses}
              checkboxHandler={handleTableCheckbox}
            />
          </div>
        </div>

        <div className="absoluteKurskatCal">
          <div className="container">
            <div className="row">
              <div className="calendarclickdiv">
                <a
                  className="calendarclick"
                  role="button"
                  data-toggle="collapse"
                  href="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Show Calendar
                </a>
              </div>
            </div>
            <div className="row kurskatCal collapse" id="collapseExample">
              <CourseCalendar eventsList={eventsFromSelectedCourses} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// var filterCourseType = function (category) {
//   switch (category) {
//     case "Leadership Skills":
//       return "HaKo";
//     // case 'Courses in Critical Thinking and Cultural Awareness':
//     // return 'ReKo'
//     // case 'Core Electives Business Administration':
//     //   return 'CE BA'
//     // case 'Core Electives Law':
//     //   return 'CE Law'
//     // case "Core Electives Economics":
//     //     return "CE Econ"
//     default:
//       return category;
//   }
// };

const returnLecturerNamesAsString = (lecturers) => {
  var lecturerNames = lecturers.map(function (lecturer) {
    // generate array of lecturers names
    return lecturer.displayName;
  });
  return lecturerNames.join(", ");
};

/**
 * This function checks if a course is a block coures by checking if any of the classes are outside
 * the determined Block course date range (e.g. break courses)
 * @param  {Array} schedule [The list of classes for this course]
 * @return {Boolean}          [Returns whether the course is block or not]
 */

const checkIfBlockCourse = (schedule) => {
  const BLOCK_START_DATE = new Date(
    process.env.REACT_APP_BLOCK_START_DATE || "2021-11-01"
  );

  const BLOCK_END_DATE = new Date(
    process.env.REACT_APP_BLOCK_END_DATE || "2021-11-14"
  );

  const coursesOutsideBlockRange = schedule.filter((event) => {
    return (
      new Date(event.eventDate) <= BLOCK_START_DATE ||
      new Date(event.eventDate) >= BLOCK_END_DATE
    );
  });

  // if the array has at least one value
  // then this course is not a block course
  if (coursesOutsideBlockRange.length > 0) {
    return false;
  } else {
    return true;
  }
};

// check if exam is central or decentral
var filterExamType = function (examObj) {
  var examType = "";

  if (examObj.isCentral === true && examObj.isDeCentral === true) {
    examType = "Zentral & Dezentral";
  } else if (examObj.IsCentral === true) {
    examType = "Zentral";
  } else {
    examType = "Dezentral";
  }
  return examType;
};

var generateSpecificCourseInfoAr = function (course) {
  // TODO: Refactor to another function
  var specificCourseInfoAr = course.courses.map(function (obj) {
    // array.prototype.filter to get the calendarentries of this course
    const schedule = course.calendarEntry.filter(function (calEntry) {
      return calEntry.courseNumber === obj.courseNumber;
    });
    // go through courses array to get info
    const specificCourseInfo = {};
    specificCourseInfo.courseId = obj.courseNumber;
    specificCourseInfo.name = obj.shortName;
    specificCourseInfo.timeTableLink = obj.timeTableLink;
    specificCourseInfo.lecturersNames = returnLecturerNamesAsString(
      obj.lecturers
    );
    specificCourseInfo.isBlockCourse = checkIfBlockCourse(schedule);

    // set date info for schedule
    specificCourseInfo.schedule = [];
    schedule.forEach(function (event) {
      specificCourseInfo.schedule.push({
        courseId: obj.courseNumber,
        title: obj.shortName,
        date: event.eventDate ? event.eventDate : null,
        duration: event.durationInMinutes,
        weekDay: event.weekDay ? event.weekDay : null,
        room: event.room,
        id: event.id,
      });
    });

    // only get name & entityId of lecturers
    specificCourseInfo.lecturers = [];
    obj.lecturers.forEach(function (lecturer) {
      specificCourseInfo.lecturers.push({
        name: lecturer.displayName,
        hsgId: lecturer.hsgEntityId,
      });
    });

    return specificCourseInfo;
  });
  return specificCourseInfoAr;
};

const generateCourseObject = (course) => {
  const examParts = [];
  // console.log(course.AchievementParts)
  // console.log(typeof(course.AchievementParts))
  course.achievementParts.forEach(function (examPart) {
    examParts.push({
      weight: examPart.weightage / 100,
      name: examPart.achievementForm.shortName,
      desc: examPart.achievementForm.description,
      isCentral: examPart.achievementForm.isCentral, //  Bool, isCentral -> false generally means exam part is decentral
      duration: examPart.duration ? examPart.duration : null,
    });
  });
  // console.log(examParts)

  const generalCourseInfoObj = {
    //  _id = maincourse.Id,
    // checked: false,
    // semId: semId,
    lang: course.courseLanguage.code,
    credits: course.credits / 100,
    category: course.classification,

    examContent: course.achievementContent,
    examLit: course.achievementLiterature,
    examType: filterExamType(course.achievementFormStatus),
    examParts: examParts,

    desc: course.courseContent,
    courseLit: course.courseLiterature,
    addInfo: course.courseAdditionalInformation,
    prereq: course.coursePrerequisites,
    syllabus: course.courseStructure,
  };

  const specificCourseInfoAr = generateSpecificCourseInfoAr(course);
  // merge the 2 coures ojbects into final one
  // var courseDataAr = generateCourseDataAr( specificCourseInfoAr, generalCourseInfoObj );
  // TODO : REFACTOR INTO OWN FUNCTION
  const courseDataAr = specificCourseInfoAr.map(function (specificCourseInfo) {
    // copy specific course & genereal course data into new empty object
    // and return the new merged object
    const courseObject = Object.assign(
      {},
      specificCourseInfo,
      generalCourseInfoObj
    ); //"original" datasource
    // console.log(courseObject);
    return courseObject;
  });
  return courseDataAr;
};

export default App;
